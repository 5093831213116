@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .striped div:nth-child(even) {
    @apply bg-gray-100;
  }
}

.font-dm-sans {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}

.font-nunito {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

.review .apexcharts-legend {
  display: none !important;
}

.review .apexcharts-xaxis-texts-g {
  margin-top: -2rem !important;
}

.rdt_TableHeadRow {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-arrow.slick-prev {
  fill: black;
  color: black;
  padding-left: 8px;
}
.slick-arrow.slick-next {
  fill: black;
  color: black;
  padding-right: 8px;
}

.campaign .rdp {
  border: 1px solid #e5e7eb;
  padding: 12px 16px;
  border-radius: 4px;
}
.campaign .rdp-nav_icon {
  font-size: 13px !important;
}

.campaign .rdp-caption_label {
  font-size: 13px !important;
  font-weight: 500;
}

.campaign .rdp-cell {
  font-size: 13px !important;
  font-weight: 500;
}

.campaign .rdp-head {
  font-size: 13px !important;
  font-weight: 500;
}

.campaign .rdp-day_selected {
  background-color: #2960bf;
}

.campaign .rdp-day_selected:hover {
  background-color: #2960bf;
}

.campaign .rdp-tfoot {
  font-size: 13px !important;
}

/* .slick-slide > *{
  width: fit-content !important;
  padding: 1.5rem 3rem;
  background: red;
} */
/* <div
  data-index="4"
  class="slick-slide slick-active"
  tabindex="-1"
  aria-hidden="false"
  style="outline: none; width: 311px;"
  > <div
  > <div
  class="py-2.5 px-4.5 text-sm font-medium bg-white text-center text-gray-800 border border-gray-100 rounded-full"
  tabindex="-1"
  style="width: 100%; display: inline-block;"
  > Laundry</div
  > </div
  > </div */
.slick-slide {
  /* background: #000; */
  width: fit-content;
  padding-inline: 1rem;
}

.slick-active {
  width: fit-content;
  /* background: red; */
  padding-inline: 1rem;
}

.slider-container {
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.slick-arrow.slick-prev:hover {
  background: gray;
  padding-left: 8px;
}

.slick-arrow.slick-next:hover {
  background: gray;
  padding-right: 8px;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.bg-secondary-2 {
  background: linear-gradient(
    92.86deg,
    rgba(166, 98, 255, 0.3) -6.41%,
    rgba(55, 125, 255, 0.3) 94.2%
  );
}

.bg-gradient-primary {
  background: linear-gradient(
    to bottom right,
    rgba(166, 98, 255, 1) 10%,
    rgba(85, 145, 255, 1)
  );
}

.bg-primary {
  background: #1d4ed8;
}

.bg-primary:hover {
  background: #1e40af;
}

/* @media screen and (max-width: 768px) {
  .bg-primary {
    background: #1d4ed8;
  }
  .bg-primary:hover {
    background: #1e40af;
  }
} */

.bg-gradient-secondary {
  background: linear-gradient(
    to bottom right,
    rgba(85, 145, 255, 1) 15%,
    rgba(56, 203, 137, 1)
  );
}

@media screen and (max-width: 500px) {
  .social-flex-col {
    flex-direction: column;
  }
}

.passwordBoard {
  display: none;
}

@media screen and (min-width: 1320px) {
  .passwordBoard {
    display: block;
  }
  .registerPageDiv {
    margin: 0;
  }
  .showPasswordDiv {
    display: none;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.generalScrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.ticketsCardsScrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.mainScrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.chatBotScrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: #b0d3d3;
}

.chatBotScrollbar::-webkit-scrollbar-thumb {
  background: #3b78e2c3;
}

.asideScrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.ticketsCardsScrollbar::-webkit-scrollbar-thumb {
  background: #b0d3d3;
  border-radius: 5px;
}

.notificationScrollbar {
  scrollbar-gutter: stable;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9c9c;
  border-radius: 5px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

@media screen and (max-width: 500px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

@media screen and (max-width: 360px) {
  .hide-help {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .customizer_css {
    display: none !important;
  }
}

@media screen and (min-width: 1000px) {
  .customizer_mobile_css {
    display: none !important;
  }
}
